<template>
	<div class="allbottom">
		<login ref='child'></login>
		<div class="allchoose">
			<div class="sanchoose">
				<div class="allspan" v-for="(item,index) in chooselist" :key="index">
					<div :class="['mr28', bchoose==item.id ? 'mr28 mr28c':'']" @click="tbchoose(item.id,index)">{{item.cat_name}}</div>
					<div v-if="index<(chooselist.length-1)" class="allspanline"></div>
				</div>
			</div>
		</div>
		<div class="allscancode">
			<div>
				扫码关注
			</div>
			<img @mouseenter="showscancode=1" @mouseleave="showscancode=0" src="../../assets/img/home/wechat.png" />
			<div class="centang" v-if="showscancode==1"></div>
			<div class="allscancode-d" v-if="showscancode==1" @mouseleave="showscancode=0">
				<div class="allscancode-d-div">
					<div>扫码进入逸教课堂微信小程序</div>
					<img src="../../assets/img/courseDetail/erweima.png" />
				</div>
			</div>
		</div>
		<div class="allbottomlogo"><img class="logo" :src="baseinfo.app_logo" /></div>
		<div class="allbottomtext">{{baseinfo.company_name}}</div>
		<div class="allbottomtext" @click="toicp()">{{baseinfo.icp}}{{baseinfo.app_name}}{{baseinfo.copyright}}</div>
		<div class="bottomadv">
			<div v-for="(item,index) in dataadv" :key="index" @click="toxieyi(item.title,item.id)">
				<div :class="['bottomadvitem',index==dataadv.length-1 ? 'bottomadvitem bottomadviteml':'']">
					{{item.title}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import login from './login.vue';
	export default {
		components: {
			login
		},
		data() {
			return {
				bchoose: 1,
				showscancode: 0,
				dataadv: [],
				chooselist: [{
					name: "职场提升",
					id: 1,
					index: "/home"
				}, ],
				baseinfo: {

				}
			};
		},
		computed: {

		},
		created() {
			this.getbase()
			this.getgoodscate()
			this.getprotocollist()
		},
		methods: {
			topage(e) {},
			toxieyi(name, id) {
				this.$router.push({
					path: '/xieyi',
					query: {
						name: name,
						id: id
					}
				})
			},
			toicp() {
				window.open("https://beian.miit.gov.cn/", "_blank");
			},
			tbchoose(e, index) {
				this.bchoose = e
				if (!localStorage.getItem('token')) {
					// this.$refs.child.toshow(1);
					this.$message.error("请登录")
					return
				}
				this.$router.push({
					path: '/search',
					query: {
						id: e,
						data: this.chooselist,
						choose: index,
					}
				})
			},
			//获取网站基本信息
			getbase() {
				this.$get("index/getbaseinfo", {}).then(response => {
					if (response.error == 0) {
						this.baseinfo = response.data.baseInfo
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getprotocollist() {
				this.$get("index/getprotocollist", {}).then(response => {
					if (response.error == 0) {
						this.dataadv = response.data.protocol
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getgoodscate(data) {
				var that = this
				this.chooselist =data
			},
		},

	};
</script>

<style>
	.allbottom :hover {
		color: #00D0B0 !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
	}

	.allchoose {
		width: 100%;
		height: 52px;
		background: #FFFFFF;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.sanchoose {
		background: #FFFFFF;
		font-size: 16px;
		width: 82%;
		margin-left: auto;
		margin-right: auto;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		padding-top: 18px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		color: #333333;
	}

	.allspan {
		text-align: center;
		line-height: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-left: 36px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		color: #333333;
		cursor: pointer;
	}

	.mr28 {
		margin-right: 39px;
	}

	.mr28c {
		color: #00D0B0 !important;
	}

	.allspanline {
		width: 2px;
		height: 20px;
		background: #E6E6E6;
	}

	a {
		text-decoraction: none;
		color: #333333;
	}

	.allbottom {
		width: 100%;
		height: 328px;
		background: #3D3D3D;
	}

	.allbottomlogo {
		width: 96px;
		height: 96px;
		font-size: 24px;
		text-align: center;
		margin: 0 auto;
		line-height: 96px;
		margin-top: 51px;
		border: 1px solid #E6E6E6;
		border-radius: 50%;
	}

	.logo {
		width: 96px;
		height: 96px;
		border-radius: 50%;
	}

	.allbottomtext {
		width: 100%;
		text-align: center;
		font-size: 16px;
		color: #FFFFFF;
		margin-top: 17px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
	}

	.allscancode {
		height: 20px;
		position: absolute;
		left: 80%;
		padding-top: 30px;
		margin: auto;
		display: flex;
		z-index: 300;
		cursor: pointer;
		flex-wrap: wrap;
		align-items: flex-start;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #CCCCCC;
	}

	.allscancode img {
		height: 40px;
		width: 40px;
		margin-left: 19px;
		margin-top: -10px;
	}

	.allscancode-d {
		margin-top: -195px;
		margin-left: -112px;
		width: 210px;
		height: 177px;
		background: #FFFFFF;
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
	}

	.allscancode-d-div {
		text-align: center;
		width: 90%;
		margin-left: 5%;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 14px;
		color: #333333;
	}

	.allscancode-d img {
		height: 91px;
		width: 91px;
		margin: auto;
		margin-top: 20px;
	}

	.centang {
		width: 0;
		height: 0;
		margin-left: -26px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 10px solid #FFFFFF;
		margin-top: -20px;
	}

	.bottomadv {
		height: 50px;
		width: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bottomadvitem {
		height: 100%;
		width: 138px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		cursor: pointer;
		text-align: center;
		border-right: 1px solid #FFFFFF;
		color: #E7E5E5;
	}

	.bottomadviteml {
		border-right: none !important;
	}
</style>
