<template>
	<div class="helppage">
		<div class="helphead" @click="tohome">
			<div class="title1">逸教课堂</div>
			<div class="title2">{{xieyi}}</div>
		</div>
		<el-divider></el-divider>
		<div class="xieyicotent" v-html="content">

		</div>
		<vbottom></vbottom>
	</div>
</template>

<script>
	import vbottom from '../common/bottom.vue';
	export default {
		components: {
			vbottom
		},
		data() {
			return {
				xieyi: "课堂协议",
				content: "<p>12345678</p>"
			}
		},
		watch: {
			'$route': function() {
				this.xieyi = this.$route.query.name
				this.getxieyi(this.$route.query.id)
			}
		},
		mounted() {
			this.xieyi = this.$route.query.name
			this.getxieyi(this.$route.query.id)
		},
		methods: {
			tohome() {
				this.$router.push({
					path: '/home'
				})
			},
			//帮助详情
			getxieyi(a) {
				this.$get("index/protocol/" + a, {}).then(response => {
					if (response.error == 0) {
						this.content = response.data.protocol.content
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			changeTabs(a) {
				this.choose = a
				this.gethelp(a)
			}
		}
	}
</script>

<style scoped>
	.helphead {
		width: 100%;
		height: 100px;
		line-height: 100px;
	}

	.helpheada {
		width: 100%;
		height: 50px;
		line-height: 50px;
	}

	.page-item {
		width: 100%;
		height: 100px;
		padding-left: 10%;
	}

	.title1 {
		float: left;
		font-size: 42px;
		font-family: HuXiaoBo-NanShen;
		font-weight: 400;
		color: #00D0B0;
		margin-left: 181px;
		line-height: 100px;
		cursor: pointer;
		margin-right: 15px;
	}

	.title2 {
		display: inline-block;
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #00D0B0;
		cursor: pointer;
		line-height: 100px;
	}

	.xieyicotent {
		min-height: 450px;
		height: auto;
		width: 80%;
		margin-left: 10%;
		margin-bottom: 20px;
	}
</style>
